import React, { useState, useEffect } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import { QAInterface } from "./QAInterface";
import clsx from 'clsx';

function XmlFileViewer({ setIsLoading, searchInput, expandAll, collapseAll, isLoading }) {
    const [content, setContent] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [isResultsExpanded, setIsResultsExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isChatOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('Document');

    useEffect(() => {
        fetch('/files/title_21_HIJ.html')
            .then(response => response.text())
            .then(htmlData => {
                // Parse HTML string and extract h1 and info contents
                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(htmlData, 'text/html');
                const h1Element = htmlDoc.querySelector('h1');
                const infoElements = htmlDoc.querySelectorAll('.info');

                // Open details elements up to the third level (part level)
                const detailsElements = htmlDoc.querySelectorAll('details');
                detailsElements.forEach(details => {
                    let level = 0;
                    let parent = details.parentElement;
                    while (parent) {
                        if (parent.tagName.toLowerCase() === 'details') {
                            level++;
                        }
                        parent = parent.parentElement;
                    }
                    if (level < 2) {
                        details.open = true;
                    }
                });

                // Remove h1 and info elements from the rest of the content
                if (h1Element) h1Element.remove();
                infoElements.forEach(el => el.remove());
                const updatedContent = htmlDoc.documentElement.innerHTML;
                setContent(updatedContent);

                setIsLoading(false);
            })
            .catch(error => console.error('Error:', error));
    }, [setIsLoading]);

    const getPath = (element) => {
        let path = [];
        while (element) {
            const summary = element.querySelector('summary');
            if (summary) {
                let summaryText = summary.textContent;
                if (element.querySelector('details')) { // if there is a child 'details' element
                    summaryText = summaryText.replace('—', ' ');
                    // Moved slicing logic outside the loop
                }
                path.unshift(summaryText);
            }
            element = element.parentElement.closest('details');
        }
        // Apply slicing logic to all elements except the last one
        path = path.map((text, index) => {
            if (index < path.length - 1) { // Check if it's not the last element
                let modifiedText = text.split(' ').filter(word => word !== "").slice(0, 2).join(' '); // keep only the first two words
                modifiedText = modifiedText.replace(/[.,/#!$%^&*;:{}=\-_`~()]$/, ""); // remove the last punctuation
                return modifiedText;
            }
            return text;
        });

        return path.reduce((prev, curr, i) => {
            return [...prev, i > 0 ? <i className="fas fa-arrow-right mx-2 text-dark" /> : null, curr];
        }, []);
    };

    useEffect(() => {
        if (searchInput && searchInput.length > 1) {
            setLoading(true);
            const timeoutId = setTimeout(() => {
                const allDetailsElements = document.querySelectorAll('details');
                const matchingElements = Array.from(allDetailsElements).filter(el => {
                    const summary = el.querySelector('summary');
                    const directTextContent = Array.from(el.childNodes).filter(node => node.nodeType === 3).map(node => node.textContent).join('');
                    if ((summary && summary.textContent.toLowerCase().includes(searchInput.toLowerCase())) || directTextContent.toLowerCase().includes(searchInput.toLowerCase())) {
                        return true;
                    }
                    return false;
                });
                const matchingPaths = matchingElements.map(getPath);
                setSearchResults(matchingPaths);
                setIsResultsExpanded(true);
                setLoading(false);
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [searchInput, setIsLoading]);
    return (
        <div className='card card-custom'>
            <div className='card-header card-header-stretch'>
                <ul
                    className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent flex-nowrap'
                    role='tablist'
                >
                    <li className='nav-item fs-4'>
                        <h5
                            className={clsx('nav-link cursor-pointer fw-semibold', { 'active text-dark fw-bold': activeTab === 'Document' })}
                            onClick={() => setActiveTab('Document')}
                            role='tab'
                        >
                            Document
                        </h5>
                    </li>
                    <li className='nav-item fs-4'>
                        <h5
                            className={clsx('nav-link cursor-pointer fw-semibold', { 'active text-dark fw-bold': activeTab === 'LeximQ&A' })}
                            onClick={() => setActiveTab('LeximQ&A')}
                            role='tab'
                        >
                            Lexim Q&A
                        </h5>
                    </li>
                </ul>
            </div>
            <div className='card-body'>
                <div className='tab-content'>
                    <div className={clsx('tab-pane', { active: activeTab === 'Document' })}>
                        <div>
                            <div className="d-flex align-items-center mb-4 justify-content-between">
                                <div className="d-flex align-items-center" style={{ visibility: searchInput ? 'visible' : 'hidden' }}>
                                    <h5 className="mt-2 mb-0 ms-5">
                                        Found {searchResults.length} matching results for: {" "}
                                        <span className="text-primary font-italic">{searchInput}</span>
                                    </h5>
                                    <button className="btn btn-sm btn-primary ms-4 mt-2" onClick={() => setIsResultsExpanded(!isResultsExpanded)}>
                                        {isResultsExpanded ? 'Hide Results' : 'Show Results'}
                                    </button>
                                    {loading && <div className="spinner-border text-primary ms-4 mt-2" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>}
                                </div>
                                <div className="d-flex align-items-center ml-auto">
                                    {/* <div className="btn-group me-5 mt-2" role="group" aria-label="Expand and Collapse buttons">
                                        <button type='button' className='btn btn-sm btn-primary border-end border-3' onClick={expandAll}>
                                            <KTIcon iconName='plus' className='fs-1' />
                                        </button>
                                        <button type='button' className='btn btn-sm btn-primary' onClick={collapseAll}>
                                            <KTIcon iconName='minus' className='fs-1' />
                                        </button>
                                    </div> */}
                                    <button className="btn btn-sm btn-primary mt-2 me-5" onClick={() => {
                                        const firstSummary = document.querySelector('summary');
                                        const initialScrollY = window.scrollY;

                                        if (firstSummary) {
                                            firstSummary.scrollIntoView({ behavior: 'smooth' });

                                            setTimeout(() => {
                                                if (window.scrollY > initialScrollY || window.scrollY > 1) {
                                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                                }
                                            }, 5);
                                        } else {
                                            // If no summary, just scroll to top
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }
                                    }}>
                                        <KTIcon iconName='arrow-up' className='fs-1' />
                                        Scroll to Top
                                    </button>
                                </div>
                            </div>
                            {searchInput && searchInput.length > 1 && isResultsExpanded &&
                                <div className="list-group list-group-flush mb-5" style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                    {searchResults.map((result, index) => (
                                        < div key={`${result.join('')}-${index}`} className="list-group-item mb-3"  >
                                            <span style={{ color: '#000000' }}>{result.slice(0, -1)}</span>
                                            <span style={{ cursor: 'pointer', color: '#007bff' }} onClick={() => {
                                                // Find the corresponding details element
                                                const allDetailsElements = document.querySelectorAll('details');
                                                const detailsElement = Array.from(allDetailsElements).find(el => {
                                                    const path = getPath(el);
                                                    return JSON.stringify(path) === JSON.stringify(result);
                                                });
                                                const initialScrollY = window.scrollY;
                                                if (detailsElement) {
                                                    // Open the details element and all its parent details elements
                                                    let element = detailsElement;
                                                    while (element) {
                                                        element.open = true;
                                                        element = element.parentElement.closest('details');
                                                    }
                                                    // Scroll to the details element
                                                    detailsElement.scrollIntoView({ behavior: 'smooth' });
                                                    setTimeout(() => {
                                                        if (window.scrollY > initialScrollY || window.scrollY > 1) {
                                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                                        }
                                                    }, 10);
                                                }
                                            }}>
                                                {result.slice(-1)}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            }
                            <div className="container p-3 text-wrap mx-auto xml-file-viewer-container d-flex flex-row"> {/* Add flex-row here */}
                                <div className={`text-wrap ${isChatOpen ? 'col-7' : 'col-12 me-20'}`}
                                    style={{
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                        whiteSpace: 'pre-wrap',
                                        boxShadow: '1px 0 1px -1px rgba(0,0,0,0.25)',
                                        paddingRight: '75px',
                                        scrollbarWidth: 'auto',
                                        maxHeight: isResultsExpanded ? 'calc(100vh - 380px)' : 'calc(100vh - 230px)',
                                        flex: 1 // Add flex: 1 here
                                    }}
                                >

                                    {content ?
                                        <div dangerouslySetInnerHTML={{ __html: content }} /> :
                                        <p></p>
                                    }
                                </div>
                                {/* <QAInterface style={{ flex: 1 }} /> */}
                            </div>
                        </div >
                    </div>
                    <div className={clsx('tab-pane', { active: activeTab === 'LeximQ&A' })}>
                        <div>
                            <div className="d-flex align-items-center mb-4 justify-content-between">
                                <div className="d-flex align-items-center" style={{ visibility: searchInput ? 'visible' : 'hidden' }}>
                                    <h5 className="mt-2 mb-0 ms-5">
                                        Found {searchResults.length} matching results for: {" "}
                                        <span className="text-primary font-italic">{searchInput}</span>
                                    </h5>
                                    <button className="btn btn-sm btn-primary ms-4 mt-2" onClick={() => setIsResultsExpanded(!isResultsExpanded)}>
                                        {isResultsExpanded ? 'Hide Results' : 'Show Results'}
                                    </button>
                                    {loading && <div className="spinner-border text-primary ms-4 mt-2" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>}
                                </div>
                                <div className="d-flex align-items-center ml-auto">
                                    {/* <div className="btn-group me-5 mt-2" role="group" aria-label="Expand and Collapse buttons">
                                        <button type='button' className='btn btn-sm btn-primary border-end border-3' onClick={expandAll}>
                                            <KTIcon iconName='plus' className='fs-1' />
                                        </button>
                                        <button type='button' className='btn btn-sm btn-primary' onClick={collapseAll}>
                                            <KTIcon iconName='minus' className='fs-1' />
                                        </button>
                                    </div> */}
                                    <button
                                        className="btn btn-sm btn-primary mt-2 me-5"
                                        onClick={() => {
                                            const contentContainer = document.querySelector('.xml-file-viewer-container .card-body');
                                            const initialScrollY = window.scrollY;
                                            if (!contentContainer) {
                                                console.warn('Content container not found');
                                                return;
                                            }
                                            const firstSummary = contentContainer.querySelector('summary');

                                            if (firstSummary) {
                                                firstSummary.scrollIntoView({ behavior: 'smooth' });
                                                setTimeout(() => {
                                                    if (window.scrollY > initialScrollY || window.scrollY > 1) {
                                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                                    }
                                                }, 5);
                                            } else {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                                console.error('No summary elements found within the content container');
                                            }
                                        }}
                                    >
                                        <KTIcon iconName="arrow-up" className="fs-1" />
                                        Scroll to Top
                                    </button>
                                </div>
                            </div>
                            {searchInput && searchInput.length > 1 && isResultsExpanded &&
                                <div className="list-group list-group-flush mb-5" style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                    {searchResults.map((result, index) => (
                                        < div key={`${result.join('')}-${index}`} className="list-group-item mb-3"  >
                                            <span style={{ color: '#000000' }}>{result.slice(0, -1)}</span>
                                            <span style={{ cursor: 'pointer', color: '#007bff' }} onClick={() => {
                                                const contentContainer = document.querySelector('.xml-file-viewer-container .card-body');
                                                const initialScrollY = window.scrollY;
                                                if (!contentContainer) {
                                                    console.warn('Content container not found');
                                                    return;
                                                }
                                                const allDetailsElements = contentContainer.querySelectorAll('details');
                                                const detailsElement = Array.from(allDetailsElements).find(el => {
                                                    const path = getPath(el);
                                                    return JSON.stringify(path) === JSON.stringify(result);
                                                });
                                                if (detailsElement) {
                                                    // Open the details element and all its parent details elements
                                                    let element = detailsElement;
                                                    while (element) {
                                                        element.open = true;
                                                        element = element.parentElement.closest('details');
                                                    }
                                                    // Scroll to the details element
                                                    detailsElement.scrollIntoView({ behavior: 'smooth' });
                                                    setTimeout(() => {
                                                        if (window.scrollY > initialScrollY || window.scrollY > 1) {
                                                            window.scrollTo({ top: initialScrollY, behavior: 'smooth' });
                                                        }
                                                    }, 5);
                                                }
                                            }}>
                                                {result.slice(-1)}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            }
                            <div className="container p-3 text-wrap mx-auto xml-file-viewer-container d-flex flex-row"> {/* Add flex-row here */}
                                <div className={`card-body text-wrap ${isChatOpen ? 'col-7' : 'col-12 me-20'}`}
                                    style={{
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                        whiteSpace: 'pre-wrap',
                                        boxShadow: '1px 0 1px -1px rgba(0,0,0,0.25)',
                                        paddingRight: '50px',
                                        maxHeight: isResultsExpanded ? 'calc(100vh - 380px)' : 'calc(100vh - 230px)',
                                        flex: 2 // Add flex: 1 here
                                    }}
                                >

                                    {content ?
                                        <div dangerouslySetInnerHTML={{ __html: content }} /> :
                                        <p></p>
                                    }
                                </div>
                                <QAInterface style={{ flex: 1 }} />
                            </div>
                        </div >
                    </div>
                </div >
            </div>
        </div >
    );
}

export default XmlFileViewer;
