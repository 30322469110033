import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import { useEffect, useState } from 'react';
// import { KTIcon } from '../../../../../_metronic/helpers';
import { Modal, Button } from 'react-bootstrap';
// import { useAuth } from '../../../../modules/auth';

const supportFormSchema = Yup.object().shape({
    resolution: Yup.string().required('Request type is required'),
    response: Yup.string().required('A response is required'),
});

const ResolveFeedbackModal = ({ feedback, displayModal, onClose, fetchFeedback }) => {
    const [showModal, setShowModal] = useState(displayModal);
    const [loading, setLoading] = useState(false);
    // const { currentUser } = useAuth();

    useEffect(() => {
        setShowModal(displayModal);
    }, [displayModal]);

    const closeModal = () => {
        setShowModal(false);
        onClose(); // Call the onClose function passed as a prop
    }

    const formik = useFormik({
        initialValues: {
            resolution: feedback?.resolution,
            response: feedback?.lexim_response || '',
        },
        validationSchema: supportFormSchema,
        onSubmit: async (values) => {
            setLoading(true)
            try {
                // Change the format of the values here
                const formattedValues = {
                    ...values,
                    id: feedback?.id,
                    columns: {
                        resolution: values.resolution,
                        viewed_by_lexim: true,
                        lexim_response: values.response,
                        lexim_viewed_date: new Date(),
                    }
                };

                await axios.patch(`reg/v1/superadmin/feedback`, formattedValues);
                formik.resetForm();
                fetchFeedback();
                alert('Feedback submitted successfully')
                closeModal();
            } catch (error) {
                console.error('Failed to submit feedback:', error);
            } finally {
                setLoading(false);
            }
        }
    });

    const isDisabled = formik.initialValues.resolution === "Closed";

    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Feedback Response {isDisabled ? 'Details' : 'Form'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={formik.handleSubmit}>
                    <div style={{ maxHeight: 'calc(80vh - 50px)', overflowY: 'scroll' }}>
                        <div className='row mt-n4'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Type</label>
                        </div>
                        <div className='row mt-n3 mb-2'>
                            <div className='col-lg-12'>
                                <div>{feedback?.subject.charAt(0).toUpperCase() + feedback?.subject.slice(1)}</div>
                            </div>
                        </div>
                        <div className='row'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Author</label>
                        </div>
                        <div className='row mt-n3 mb-2'>
                            <div className='col-lg-12'>
                                <div>{feedback?.user_full_name}</div>
                            </div>
                        </div>
                        <div className='row'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Content</label>
                        </div>
                        <div className='row mt-n3 mb-2'>
                            <div className='col-lg-12'>
                                <div>{feedback?.feedback}</div>
                            </div>
                        </div>
                        {(feedback?.viewed_by_lexim !== 0 || feedback?.viewed_by_orgadmin !== 0) &&
                            <div>
                                <div className='row'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Viewed By</label>
                                </div>
                                <div className='row mt-n3 mb-n3'>
                                    <div className='col-lg-12'>
                                        <ul>
                                            {feedback?.viewed_by_lexim === 1 &&
                                                <li>Lexim Admin on {new Date(feedback?.lexim_viewed_date).toLocaleString('en-US', { timeZone: 'UTC' })}</li>
                                            }
                                            {feedback?.viewed_by_orgadmin === 1 &&
                                                <li>Org Admin on {new Date(feedback?.orgadmin_viewed_date).toLocaleString('en-US', { timeZone: 'UTC' })}</li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {(feedback?.orgadmin_response && feedback?.resolution !== 'Closed') &&
                            <div className="mb-2">
                                <div className='row'>
                                    <label className='col-lg-5 col-form-label fw-bold fs-6'>Org Admin Response</label>
                                </div>
                                <div className='row mt-n3 mb-2'>
                                    <div className='col-lg-12'>
                                        <div>{feedback?.orgadmin_response}</div>
                                    </div>
                                </div>
                            </div>
                        }
                        {feedback?.resolution === 'Closed' ?
                            <div className="mb-7">
                                <div className='row'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Resolution</label>
                                </div>
                                <div className='row mt-n3 mb-2'>
                                    <div className='col-lg-12'>
                                        <div>{feedback?.resolution}</div>
                                    </div>
                                </div>
                                {feedback?.lexim_response &&
                                    <div className="mb-2">
                                        <div className='row'>
                                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Lexim Admin Response</label>
                                        </div>
                                        <div className='row mt-n3 mb-2'>
                                            <div className='col-lg-12'>
                                                <div>{feedback?.lexim_response}</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {feedback?.orgadmin_response &&
                                    <div className="mb-2">
                                        <div className='row'>
                                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Org Admin Response</label>
                                        </div>
                                        <div className='row mt-n3 mb-2'>
                                            <div className='col-lg-12'>
                                                <div>{feedback?.orgadmin_response}</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                <div className='row mt-6'>
                                    <label style={{ width: '30%' }} className='col-form-label required fw-bold fs-6 mb-1'>Resolution</label>
                                    <div style={{ width: '60%', marginTop: '0.25rem' }} className='fv-row' >
                                        <select
                                            name="resolution"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.resolution}
                                            className='form-control form-control-lg form-control-solid'
                                            style={{
                                                backgroundColor: isDisabled ? '#f2f2f2' : '#f5f5f5',
                                                color: isDisabled ? '#6c757d' : 'black',
                                                appearance: 'none',
                                                backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='black' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l3 3 3-3' /></svg>")`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'right .5rem center',
                                                backgroundSize: '1.5em'
                                            }}
                                            disabled={isDisabled}
                                        >
                                            <option value="Open">Open</option>
                                            <option value="Under Review">Under Review</option>
                                            <option value="Closed">Closed</option>
                                        </select>
                                        {/* {formik.touched.resolution && formik.errors.resolution && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block' style={{ marginLeft: '10px', marginTop: '7px', fontSize: '12px' }}>{formik.errors.resolution}</div>
                                </div>
                            )} */}
                                    </div>
                                </div>


                                <div className='row mt-6'>
                                    <label className='col-lg-5 col-form-label required fw-bold fs-6'>Lexim Admin Response</label>
                                </div>
                                <div className='row mb-8'>
                                    <div className='col-12 fv-row'>
                                        <textarea
                                            name="response"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.response}
                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                            rows={7}
                                            maxLength={1000}
                                            style={{
                                                backgroundColor: isDisabled ? '#f2f2f2' : '#f5f5f5',
                                                color: isDisabled ? '#6c757d' : 'black'

                                            }}
                                            disabled={isDisabled}
                                        />
                                        {!isDisabled &&
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {formik.touched.response && formik.errors.response ? (
                                                    <div className='fv-help-block' style={{ marginLeft: '10px', marginTop: '5px', fontSize: '12px' }}>
                                                        {typeof formik.errors.response === 'string' ? formik.errors.response : ''}
                                                    </div>
                                                ) : (
                                                    <div style={{ marginLeft: '10px', marginTop: '5px', fontSize: '12px' }}>&nbsp;</div> // Placeholder div
                                                )}

                                                <div style={{ color: '#6c757d', marginRight: '15px', marginTop: '10px', fontSize: '15px' }}>
                                                    {formik.values.response.length}/1000
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                    <Modal.Footer className="justify-content-between mx-n2 mb-n5">
                        <Button variant="secondary" onClick={closeModal}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" disabled={loading || isDisabled}>
                            {loading ? 'Loading...' : 'Submit'}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export { ResolveFeedbackModal }