import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios'; // Import axios
import { KTIcon } from '../../../_metronic/helpers';
import DocumentFilter from './DocumentFilter';
import { useAuth } from '../../modules/auth';
import SearchBar from './SearchBar';
import DocumentTable from './DocumentTable';
import SortFilter from './SortFilter';
import { DocumentsListPagination } from './DocumentsListPagination';
import { MixedWidget11, MixedWidget10 } from '../../../_metronic/partials/widgets';

import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Fuse from 'fuse.js';

const Debarment = () => {
    // Define your docket details or fetch them from an API

    const [documents, setDocuments] = useState(null);
    // const { documentType } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filteredDocuments, setFilteredDocuments] = useState(null);
    const [filterName, setFilterName] = useState('');
    const [filterProgram, setFilterProgram] = useState('');
    // const [filterStartDate, setFilterStartDate] = useState('');
    // const [filterEndDate, setFilterEndDate] = useState('');
    // const [filterTracked, setFilterTracked] = useState(documentType ? true : false);
    const [filterAgency, setFilterAgency] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const { logout } = useAuth();
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [sortConfig, setSortConfig] = useState({ key: 'effective_date', direction: 'desc' });
    const [sortDirection, setSortDirection] = useState('desc');
    const [currentPage, setCurrentPage] = useState(1);
    const interests = useSelector(state => state.account.interests);
    // const [trackedDocuments, setTrackedDocuments] = useState([]);
    const limit = 18446744073709551615; // Set your desired limit here
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);
    const [totalPages, setTotalPages] = useState(0);
    const [programs, setPrograms] = useState({});
    const colors = ['#0000CD', '#FF8C00', '#A9A9A9', '#FFD700', '#1E90FF'];
    const [data, setData] = useState([]);
    const [isInsightsVisible, setIsInsightsVisible] = useState(false);
    const moment = require('moment-timezone');

    const fetchData = useCallback(() => {
        axios.get(`reg/v1/home/insights/documents`)
            .then(response => {
                const sortedData = response.data;
                setData(sortedData);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    logout();
                    navigate('/auth/login')
                }
                console.error('There was an error!', error);
            });

    }, [logout, navigate]);

    useEffect(() => {
        Promise.all([
            fetchData()
        ]).then(() => { }).catch((error) => {
            console.error(error.message);
        });
    }, [fetchData]);

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [currentPage]);

    useEffect(() => {
        if (interests) {
            const newPrograms = {};
            interests.programs.forEach(program => {
                const match = program ? program.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newPrograms[key] = value;
                }
            });
            setPrograms(newPrograms);
        }
    }, [interests]);


    const formatDate = useCallback((timestamp) => {
        if (timestamp === null) {
            return null;
        }
        const date = moment.utc(timestamp); // Use UTC
        return date.format('YYYY-MM-DD'); // Format in UTC
    }, [moment]);

    const formatDate2 = useCallback((timestamp) => {
        if (isNaN(Date.parse(timestamp))) {
            return null;
        }
        const date = moment.utc(timestamp); // Use UTC
        return date.format('YYYY-MM-DD'); // Format in UTC
    }, [moment]);

    const fetchDocuments = useCallback(async () => {
        setLoading(true);
        try {
            // Attempt to load cached documents from localStorage
            const cachedDocuments = localStorage.getItem('debarment');
            let sortedData;

            if (cachedDocuments) {
                sortedData = JSON.parse(cachedDocuments);
            } else {
                const response = await axios.get(`/reg/v1/document/debarment/${limit}/0`);
                sortedData = [...response.data];
                try {
                    localStorage.setItem('debarment', JSON.stringify(sortedData));
                }
                catch (e) {
                    if (e instanceof DOMException && e.code === DOMException.QUOTA_EXCEEDED_ERR) {
                        console.error("LocalStorage quota exceeded. Consider optimizing the data or using IndexedDB for larger storage.");
                        // Optionally, implement fallback storage or notify the user
                    } else {
                        console.log(e); // Re-throw the error if it's not a QuotaExceededError
                    }
                }

                const newPrograms = interests.programs.map(program => {
                    const match = program ? program.match(/\((.*?)\)/) : undefined;
                    return match && match.length > 1 ? match[1] : program;
                });
                if (interests.agencies.length > 0 || newPrograms.length > 0) {
                    sortedData = sortedData.filter(document =>
                        (interests.agencies.length === 0 || interests.agencies.includes(document.agencyId)) &&
                        (newPrograms.length === 0 || newPrograms.includes(document.program))
                    );
                }
                // Cache the sorted data in localStorage

            }

            setDocuments(sortedData);
            setFilteredDocuments(sortedData);
            setError(null);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                logout();
                navigate('/auth/login');
            }
            setDocuments(null);
            setFilteredDocuments(null);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [logout, navigate, interests, limit]); // Added 'limit' to the dependency array



    useEffect(() => {
        fetchDocuments();
        // fetchTrackedDocuments();
    }, [fetchDocuments, interests]);

    useEffect(() => {
        if (!loading && documents?.length > 0) {
            const delayDebounceFn = setTimeout(() => {
                if (searchInput.trim() === '') {
                    setFilteredDocuments(documents);
                } else {

                    const options = {
                        includeScore: true,
                        // Add other options based on your requirements
                        keys: [{
                            name: 'id',
                            weight: 0.5,
                            getFn: (item, path) => {
                                // Normalize by removing hyphens and converting to lowercase for comparison
                                const normalizedItemId = item.id.replace(/-/g, '').toLowerCase();
                                const normalizedSearchInput = searchInput.trim().replace(/-/g, '').toLowerCase();
                                if (normalizedItemId.includes(normalizedSearchInput)) {
                                    return [item.id]; // Return the 'id' in an array if it matches after normalization
                                }
                                return []; // Return an empty array if not a match
                            }
                        }, {
                            name: 'docketId',
                            weight: 0.4,
                            getFn: (item, path) => {
                                // Normalize by removing hyphens and converting to lowercase for comparison
                                const normalizedItemId = item.docketId.replace(/-/g, '').toLowerCase();
                                const normalizedSearchInput = searchInput.trim().replace(/-/g, '').toLowerCase();
                                if (normalizedItemId.includes(normalizedSearchInput)) {
                                    return [item.docketId]; // Return the 'id' in an array if it matches after normalization
                                }
                                return []; // Return an empty array if not a match
                            }



                        }, {
                            name: 'document_title',
                            weight: 0.3 // Added weight for document_title
                        }, {
                            name: 'dk_title',
                            weight: 0.2 // Added weight for dk_title
                        }, {
                            name: 'dateFields',
                            weight: 0.1,
                            getFn: (item, path) => {
                                // Assuming formatDate and formatDate2 return dates in 'YYYY-MM-DD' format
                                let commentEndDateET = formatDate(item.commentEndDate);
                                let commentStartDateET = formatDate(item.commentStartDate);
                                let postedDate = formatDate2(item.postedDate);
                                let modifiedDate = formatDate2(item.modifyDate);

                                // Assuming searchInput is the date in 'YYYY-MM-DD' format
                                const normalizedSearchInput = searchInput.trim();

                                let matches = [];
                                // Check if any of the date fields include the search input and add them to matches array
                                if (commentStartDateET?.includes(normalizedSearchInput)) {
                                    matches.push(commentStartDateET);
                                }
                                if (commentEndDateET?.includes(normalizedSearchInput)) {
                                    matches.push(commentEndDateET);
                                }
                                if (postedDate?.includes(normalizedSearchInput)) {
                                    matches.push(postedDate);
                                }
                                if (modifiedDate?.includes(normalizedSearchInput)) {
                                    matches.push(modifiedDate);
                                }

                                return matches; // Return an array of matching date strings
                            }
                        }, 'subtype', 'program', 'dk_Abstract', 'dk_keywords', 'topics', 'cfrPart', 'subject', 'docAbstract',
                        ],
                        ignoreLocation: true, // This helps in making the search fuzzier
                        threshold: 0.3, // Adjust this threshold to make the search stricter or fuzzier
                    };

                    // Create a new instance of Fuse with the documents and options
                    const fuse = new Fuse(documents, options);

                    // Perform the search
                    const result = fuse.search(searchInput.trim());

                    // Map the result to get the original documents
                    const filteredDocuments = result.map(resultItem => resultItem.item);

                    setFilteredDocuments(filteredDocuments);


                }
            }, 500) // delay in ms

            return () => clearTimeout(delayDebounceFn) // this will clear the timeout if the component is unmounted or if the dependencies change
        }
    }, [searchInput, documents, loading, formatDate, formatDate2]);


    const applyFilters = () => {
        if (!loading && documents?.length > 0) {

            let filtered = documents; // Start with the original list


            // Apply filters one by one
            if (filterName) {
                filtered = filtered.filter((document) =>
                    document.document_title.toLowerCase().includes(filterName.toLowerCase())
                );
            }
            if (filterProgram) {
                filtered = filtered.filter((document) => document.program === filterProgram);
            }

            if (filterAgency) {
                filtered = filtered.filter((document) =>
                    document.agencyId.toLowerCase() === filterAgency.toLowerCase()
                );
            }


            setFilteredDocuments(filtered);
        }
    };

    useEffect(() => {
        if (!loading && documents?.length > 0) {
            let filtered = documents; // Start with the original list


            // Apply filters one by one
            if (filterName) {
                filtered = filtered.filter((document) =>
                    document.document_title.toLowerCase().includes(filterName.toLowerCase())
                );
            }
            if (filterProgram) {
                filtered = filtered.filter((document) => document.program === filterProgram);
            }

            if (filterAgency) {
                filtered = filtered.filter((document) =>
                    document.agencyId.toLowerCase() === filterAgency.toLowerCase()
                );
            }


            setFilteredDocuments(filtered);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterName, filterProgram, filterAgency, loading]);

    const handleApplyFilters = () => {

        applyFilters();
        setCurrentPage(1);

    };
    const handleResetFilters = () => {
        // Clear filter state variables
        setFilterName('');
        setFilterProgram('');
        // setFilterStartDate('');
        // setFilterEndDate('');
        setFilterAgency('');
        setCurrentPage(1);
        setFilteredDocuments(documents);
    };







    const handleViewButtonPDFClick = (documentId) => {
        const url = `/viewpdf?id=${documentId}`;
        window.open(url, '_blank');

    };


    const handleAttachmentClick = (id, filetype, type) => {

        const url = `/viewattach?id=${id}&filetype=${filetype}&type=${type}`;
        window.open(url, '_blank');
    };



    const onApplySort = () => {
        if (!filteredDocuments || filteredDocuments.length === 0 || loading) {
            return;
        }

        let sortedDocuments = [...filteredDocuments];
        if (sortConfig.direction === 'asc') {
            sortedDocuments.sort((a, b) => a[sortConfig.key] > b[sortConfig.key] ? 1 : -1);
        } else if (sortConfig.direction === 'desc') {
            sortedDocuments.sort((a, b) => a[sortConfig.key] < b[sortConfig.key] ? 1 : -1);
        }
        setFilteredDocuments(sortedDocuments);
    };

    // Define onResetSort function
    const onResetSort = () => {
        if (!filteredDocuments || filteredDocuments.length === 0 || loading) {
            return;
        }
        let sortedDocuments = [...filteredDocuments];
        sortedDocuments.sort((a, b) => a.effective_date < b.effective_date ? 1 : -1);
        setSortConfig({ key: 'effective_date', direction: 'desc' });
        setFilteredDocuments(sortedDocuments);

    };
    useEffect(() => {
        Cookies.set('itemsPerPage', itemsPerPage);
    }, [itemsPerPage]);
    useEffect(() => {
        setTotalPages(Math.ceil(filteredDocuments?.length / Math.max(1, itemsPerPage)));
        if (currentPage > totalPages) {
            setCurrentPage(1);
        }
    }, [filteredDocuments, currentPage, totalPages, itemsPerPage]);
    return (

        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            {alert.message && (
                <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                    {alert.message}
                    <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                </div>
            )}
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid px-1">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                            <div className="d-flex align-items-center ms-4">
                                <KTIcon iconName='message-text-2' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                Debarment List
                                {loading && (
                                    <div className="spinner-border text-primary ms-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                )}
                            </div>
                        </h1>
                    </div>
                </div>
                <div className="d-flex flex-row-fluid px-10 align-items-center justify-content-between">
                    <h4 className="d-md-block text-wrap mt-1 text-muted">Based on user preferences</h4>
                    <button type="button" className="btn btn-sm btn-light-primary btn-active-light border border-1" onClick={() => setIsInsightsVisible(!isInsightsVisible)}>
                        <KTIcon iconName='chart-simple' className='fs-2' />
                        {isInsightsVisible ? 'Hide' : 'Show'} Insights
                    </button>
                </div>
                {interests.agencies && programs &&
                    <div className="d-flex flex-row-fluid px-10">
                        {interests.agencies.length > 0 && (
                            <div style={{ position: 'relative', paddingRight: '7px' }}>
                                <h6 className="d-md-block text-wrap mt-1">Agencies: {interests.agencies.join(', ')}</h6>
                                {Object.keys(programs).length > 0 && (
                                    <div style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '5%',
                                        bottom: '5%',
                                        width: '1px',
                                        backgroundColor: '#4d4d4d'
                                    }}></div>
                                )}
                            </div>
                        )}
                        {Object.keys(programs).length > 0 && (
                            <div style={{ paddingLeft: interests.agencies.length === 0 ? '0px' : '7px' }}>
                                <h6 className={`d-md-block text-wrap mt-1`}>Programs: {' '}
                                    {Object.entries(programs).map(([key, value], index) => (
                                        <OverlayTrigger
                                            key={index}
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-${index}`}>
                                                    {value}
                                                </Tooltip>
                                            }
                                        >
                                            <span>{index > 0 && ', '}{key}</span>
                                        </OverlayTrigger>
                                    ))}
                                </h6>
                            </div>
                        )}
                    </div>
                }
                <div className="d-flex flex-column px-10 align-items-start">
                    {(filterName || filterProgram || filterAgency) && (
                        <div className="mb-n2">
                            <h6>Active Filters:</h6>
                            <ul>
                                {filterName && <li><h6 className="d-md-block text-wrap">Name: {filterName}</h6></li>}
                                {filterAgency && <li><h6 className="d-md-block text-wrap">Agency: {filterAgency}</h6></li>}
                                {filterProgram && <li><h6 className="d-md-block text-wrap">Program: {filterProgram}</h6></li>}
                            </ul>
                        </div>
                    )}
                </div>
                {isInsightsVisible && (
                    <div className='row g-5 gx-xxl-14 justify-content-center mt-1 mb-4 px-10'>
                        <div className='col-xxl-4'>
                            <MixedWidget11
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='250px'
                                data={data}
                                title="Total Documents By Agency"
                                columns={{
                                    "Total Documents": "doc_total"
                                }}
                                activity={"all"}
                            />
                        </div>
                        <div className='col-xxl-4'>
                            <MixedWidget10
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='250px'
                                data={data}
                                title="FDA Documents By Type"
                                columns={{
                                    "Notices": "notice_total",
                                    "Other": "other_total"
                                }}
                                agency="FDA"
                                activity={"all"}
                            />
                        </div>
                        <div className='col-xxl-4'>
                            <MixedWidget10
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='250px'
                                data={data}
                                title="CMS Documents By Type"
                                columns={{
                                    "Notices": "notice_total",
                                    "Other": "other_total"
                                }}
                                agency="CMS"
                                activity={"all"}
                            />
                        </div>
                    </div>
                )}
                <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-3 mt-2`}>
                    <div className="col-7">
                        <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
                    </div>
                    <div className="d-flex flex-column-auto d-flex align-items-center ml-auto">
                        <button
                            type='button'
                            className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName='filter' className='fs-2' /> Filter
                        </button>
                        <DocumentFilter
                            filterName={filterName}
                            setFilterName={setFilterName}
                            filterProgram={filterProgram}
                            setFilterProgram={setFilterProgram}
                            // filterStartDate={filterStartDate}
                            // setFilterStartDate={setFilterStartDate}
                            // filterEndDate={filterEndDate}
                            // setFilterEndDate={setFilterEndDate}
                            filterAgency={filterAgency}
                            setFilterAgency={setFilterAgency}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}
                        />
                    </div>
                    <div className="d-flex flex-column-auto d-flex align-items-center py-1">
                        <button
                            type='button'
                            className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName={sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} iconType="solid" className='fs-2' /> Sort
                        </button>
                        <SortFilter
                            sortConfig={sortConfig}
                            setSortConfig={setSortConfig}
                            onApplySort={onApplySort}
                            onResetSort={onResetSort}
                            setSortDirection={setSortDirection}
                        />
                    </div>
                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center ml-auto ">
                        <label className="ms-5 fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"

                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />


                    </div>
                </div>

                {/* <div className="d-flex flex-column-auto  align-items-center mt-4 mx-5 p-3">


                    <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />

                    <div className="d-flex flex-column-auto  ms-4    ">
                        <button
                            type='button'
                            className='btn btn-light-primary    btn-active-light  d-flex align-items-center justify-content-center '
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'

                        >

                            <KTIcon iconName='filter' className='fs-2' /> Filter

                        </button>
                        <DocumentFilter
                            filterDocument={filterDocument}
                            setFilterDocument={setFilterDocument}
                            filterProgram={filterProgram}
                            setFilterProgram={setFilterProgram}
                            filterStartDate={filterStartDate}
                            setFilterStartDate={setFilterStartDate}
                            filterEndDate={filterEndDate}
                            setFilterEndDate={setFilterEndDate}
                            filterTracked={filterTracked}
                            filterAgency={filterAgency}
                            setFilterAgency={setFilterAgency}
                            setFilterTracked={setFilterTracked}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}

                        />
                    </div>

                    <div className="d-flex flex-column-auto  ms-4    ">
                        <button
                            type='button'
                            className='btn btn-light-primary    btn-active-light  d-flex align-items-center justify-content-center '
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'

                        >

                            <KTIcon iconName={sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} iconType="solid" className='fs-2' /> Sort

                        </button>
                        <SortFilter
                            sortConfig={sortConfig}
                            setSortConfig={setSortConfig}
                            onApplySort={onApplySort}
                            onResetSort={onResetSort}
                            setSortDirection={setSortDirection}
                        />
                    </div>
                </div> */}
                <DocumentTable loading={loading} error={error} filteredDocuments={filteredDocuments} currentPage={currentPage}
                    DOCUMENTS_PER_PAGE={Math.max(itemsPerPage, 1)} handleViewButtonPDFClick={handleViewButtonPDFClick} handleAttachmentClick={handleAttachmentClick} formatDate={formatDate} formatDate2={formatDate2} />

                {/* {filteredDocuments.length === 0 && !loading && (
                    <div className="d-flex ms-10 mb-5">
                        <h3>No documents found with selected filters. Please try again with other filters.</h3>
                    </div>
                )} */}
                {!loading && (
                    totalPages >= 1 ? (
                        <div className="d-flex justify-content-center align-items-center">

                            <div className="d-flex flex-column-auto ms-4 d-flex align-items-center">
                                <label className="ms-2 fs-6 fw-bold text-gray-700">Items per page:</label>
                                <input
                                    type="number"
                                    value={itemsPerPage}
                                    onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                                    className='form-control form-control-solid ms-2 border border-1'
                                    style={{ width: '90px', backgroundColor: '#f1faff' }}
                                />
                            </div>
                            <DocumentsListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                        </div>
                    ) : (
                        <div className="mb-4"></div>
                    )
                )}

            </div>

        </div>



    );
};

export default Debarment;






