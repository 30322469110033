// import { useNavigate} from 'react-router-dom';
import { TablesWidget } from './TablesWidget';
import { TablesWidgetRecalls } from './TablesWidgetRecalls';
// import { TablesWidgetAdverse } from '../../../_metronic/partials/widgets/tables/TablesWidgetAdverse';
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { TablesWidgetWarning } from './TablesWidgetWarning';
import { TablesWidget510 } from './TablesWidget510';
import { MixedWidgetGuidances } from './MixedWidgetGuidances';
import { MixedWidgetRegulatory } from './MixedWidgetRegulatory';
import { MixedWidgetWarning } from './MixedWidgetWarning';
import { MixedWidgetAdverse } from './MixedWidgetAdverse';
import { MixedWidget510 } from './MixedWidget510';
import { TablesWidgetPMA } from './TablesWidgetPMA';
import { MixedWidgetPMA } from './MixedWidgetPMA';
// import { TablesWidgetRegistration } from './TableWidgetRegistration';
import { MixedWidgetRecalls } from './MixedWidgetRecalls';
import { MixedWidgetRegistration } from './MixedWidgetRegistration';



const CountAndTable = ({ data, days, handleDayChange, loadingmain }) => {

    const savedDocType = Cookies.get('selectedDocType') || 'Proposed Rule';
    const [doctype, setDoctype] = React.useState(savedDocType);
    let proposedRuleCount, finalRuleCount, noticeCount, guidanceCount, recallsCount, eventsCount, lettersCount, five10kCount, pmaCount, registrationCount;

    const widgetConfig = {
        'Guidance': { component: MixedWidgetGuidances, },
        'Rule': { component: MixedWidgetRegulatory, },
        'Proposed Rule': { component: MixedWidgetRegulatory, },
        'Notice': { component: MixedWidgetRegulatory, },
        'Warning Letters': { component: MixedWidgetWarning, },
        'Adverse Events': { component: MixedWidgetAdverse, },
        '510Ks': { component: MixedWidget510, },
        'PMA': { component: MixedWidgetPMA, },
        'Recalls': { component: MixedWidgetRecalls, },
        'Registrations': { component: MixedWidgetRegistration, },
    };
    const tablewidgetComponents = {
        'Recalls': <TablesWidgetRecalls days={days} />,
        '510Ks': <TablesWidget510 days={days} />,
        'PMA': <TablesWidgetPMA days={days} />,
        'Warning Letters': <TablesWidgetWarning days={days} />,
        // 'Adverse Events': (doctype === 'Adverse Events' && (days === 30 || days === 90)) ? <div className="d-flex justify-content-center mt-4 align-items-center">
        //     <div className="alert alert-danger" role="alert">
        //         <div className="text-center  ">Sorry! Adverse Events for 30 days and 90 days are not available!</div>
        //     </div>
        // </div> : <TablesWidgetAdverse className='' doctype={doctype} details={events} loading={loading} />,
        // 'Registrations': <TablesWidgetRegistration className='' doctype={doctype} details={registrationDetails} loading={loading} />,
        'Guidance': <TablesWidget doctype={doctype} days={days} />,
        'Notice': <TablesWidget doctype={doctype} days={days} />,
        'Rule': <TablesWidget doctype={doctype} days={days} />,
        'Proposed Rule': <TablesWidget doctype={doctype} days={days} />
    };
    const Tablecontent = tablewidgetComponents[doctype];

    // Function to select the correct component and props
    const getWidget = (doctype) => {
        const config = widgetConfig[doctype];
        if (!config) return null; // Return null if no matching config is found

        const WidgetComponent = config.component;
        return (
            <WidgetComponent
                className='card-xxl-stretch pt-3'
                chartHeight='240px'
                days={days}
                doctype={doctype}
                activity={"all"}
                scale="logarithmic"
            />
        );
    };


    const rows: Array<{ title: string, data: string[], color: string }> = [
        {
            title: 'Proposed Rules',
            data: ["3"],
            color: '#2E8BC0'
            // color: '#145DA0'
        },
        {
            title: 'Final Rules',
            data: ["2"],
            color: '#2E8BC0'
        },
        {
            title: 'Guidance',
            data: ["2"],
            color: '#2E8BC0'
            // color: '#01497c'
        },
        {
            title: 'Warning Letters',
            data: ["0"],
            color: '#0077b6'
        },
        {
            title: 'Notice',
            data: ["0"],
            color: '#0077b6'
        },
        // {
        //     title: 'Registrations',
        //     data: ["0"],
        //     color: '#0077b6'
        // },
        {
            title: '510Ks',
            data: ["0"],
            color: '#0077b6'
        },
        {
            title: 'PMA',
            data: ["0"],
            color: '#014f86'
        },
        // {
        //     title: 'Adverse Events (MAUDE)',
        //     data: ["12"],
        //     color: '#014f86'
        // },
        {
            title: 'Recalls',
            data: ["2"],
            color: '#014f86'
            // color: '#0077b6'
        }
    ]
    const getCardIndex = (docType: string) => {
        switch (docType) {
            case 'Proposed Rule':
                return rows.findIndex(row => row.title === 'Proposed Rules');
            case 'Rule':
                return rows.findIndex(row => row.title === 'Final Rules');
            default:
                return rows.findIndex(row => row.title === doctype);
        }
    };
    const [selectedCard, setSelectedCard] = useState<number>(getCardIndex(savedDocType));
    switch (days) {
        case 7:
            proposedRuleCount = data?.proposed_rules_7;
            finalRuleCount = data?.final_rules_7;
            noticeCount = data?.notice_7;
            guidanceCount = data?.guidance_7;
            recallsCount = data?.recall_7;
            eventsCount = data?.adv_event_7;
            lettersCount = data?.warning_letter_7;
            five10kCount = data?.k_number_7;
            pmaCount = data?.pma_number_7;
            registrationCount = data?.reg_7;

            break;
        case 30:
            proposedRuleCount = data?.proposed_rules_30;
            finalRuleCount = data?.final_rules_30;
            noticeCount = data?.notice_30;
            guidanceCount = data?.guidance_30;
            recallsCount = data?.recall_30;
            eventsCount = data?.adv_event_30;
            lettersCount = data?.warning_letter_30;
            five10kCount = data?.k_number_30;
            pmaCount = data?.pma_number_30;
            registrationCount = data?.reg_30;

            break;
        case 90:
            proposedRuleCount = data?.proposed_rules_90;
            finalRuleCount = data?.final_rules_90;
            noticeCount = data?.notice_90;
            guidanceCount = data?.guidance_90;
            recallsCount = data?.recall_90;
            eventsCount = data?.adv_event_90;
            lettersCount = data?.warning_letter_90;
            five10kCount = data?.k_number_90;
            pmaCount = data?.pma_number_90;
            registrationCount = data?.reg_90;
            break;
        case 180:
            proposedRuleCount = data?.proposed_rules_180;
            finalRuleCount = data?.final_rules_180;
            noticeCount = data?.notice_180;
            guidanceCount = data?.guidance_180;
            recallsCount = data?.recall_180;
            eventsCount = data?.adv_event_180;
            lettersCount = data?.warning_letter_180;
            five10kCount = data?.k_number_180;
            pmaCount = data?.pma_number_180;
            registrationCount = data?.reg_180;
            break;
        case 365:
            proposedRuleCount = data?.proposed_rules_365;
            finalRuleCount = data?.final_rules_365;
            noticeCount = data?.notice_365;
            guidanceCount = data?.guidance_365;
            recallsCount = data?.recall_365;
            eventsCount = data?.adv_event_365;
            lettersCount = data?.warning_letter_365;
            five10kCount = data?.k_number_365;
            pmaCount = data?.pma_number_365;
            registrationCount = data?.reg_365;
            break;
        case 0:
            proposedRuleCount = data?.proposed_rules_all;
            finalRuleCount = data?.final_rules_all;
            noticeCount = data?.notice_all;
            guidanceCount = data?.guidance_all;
            recallsCount = data?.recall_all;
            eventsCount = data?.adv_event_all;
            lettersCount = data?.warning_all;
            five10kCount = data?.k_number_all;
            pmaCount = data?.pma_number_all;
            registrationCount = data?.reg_all;
            break;

        default:
            break;
    }

    return (
        <div className="d-flex flex-column mb-4">
            <div className='row mb-5 justify-content-between mx-1'>
                <div className='col-12 col-md-6 mt-1 ' style={{ background: 'linear-gradient(to bottom, #2C81AF 50%, #2C81AF 50%)', borderRadius: '0.625rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>                    {/* Content for the first column */}
                    <div className='mb-9 mt-7 '>

                        <ul className='nav d-flex align-items-center'>
                            <div className="spinner-border spinner-border-sm text-light me-2" role="status" style={{ visibility: loadingmain ? 'visible' : 'hidden' }}>
                                <span className="sr-only"></span>
                            </div>
                            <li className='nav-item'>
                                <button
                                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary btn-light fw-bold px-4 mx-1 ${days === 7 ? 'active' : ''}`}
                                    data-bs-toggle='tab'
                                    onClick={() => handleDayChange(7)}
                                >
                                    7 Days
                                </button>
                            </li>
                            <li className='nav-item'>
                                <button
                                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary btn-light fw-bold px-4 mx-1 ${days === 30 ? 'active' : ''}`}
                                    data-bs-toggle='tab'
                                    onClick={() => handleDayChange(30)}
                                >
                                    30 Days
                                </button>
                            </li>
                            <li className='nav-item'>
                                <button
                                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary btn-light fw-bold px-4 mx-1 ${days === 90 ? 'active' : ''}`}
                                    data-bs-toggle='tab'
                                    onClick={() => handleDayChange(90)}
                                >
                                    90 Days
                                </button>
                            </li>
                            <li className='nav-item'>
                                <button
                                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary btn-light fw-bold px-4 mx-1 ${days === 180 ? 'active' : ''}`}
                                    data-bs-toggle='tab'
                                    onClick={() => handleDayChange(180)}
                                >
                                    180 Days
                                </button>
                            </li>
                            <li className='nav-item mt-2 mt-sm-0'>
                                <button
                                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary btn-light fw-bold px-4 mx-1 ${days === 365 ? 'active' : ''}`}
                                    data-bs-toggle='tab'
                                    onClick={() => handleDayChange(365)}
                                >
                                    1 Year
                                </button>
                            </li>
                            <li className='nav-item mt-2 mt-sm-0'>
                                <button
                                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary btn-light fw-bold px-4 mx-1 ${days === 0 ? 'active' : ''}`}
                                    data-bs-toggle='tab'
                                    onClick={() => handleDayChange(0)}
                                >
                                    All
                                </button>
                            </li>

                        </ul>
                        <div className='pt-3 row g-5 g-xl-10'
                            style={{
                                pointerEvents: loadingmain ? 'none' : 'auto',
                                opacity: loadingmain ? 0.9 : 1
                            }}
                        >
                            {/* Your existing CountAndTable content */}
                        </div>
                    </div>
                    {/* <style>
                        {`
          @media (min-width: 992px) { 
            .custom-five {
              flex: 0 0 20%; 
              max-width: 20%; 
            }
          }
        `}
                    </style> */}
                    <div className="row gx-3 gy-3 mb-10 mt-2 mx-1"> {/* g-2 class for spacing between cards */}
                        {rows.map((row, index) => (
                            <div key={`lw26 - rows - ${index}`}
                                className={`col-6 col-md-6 col-lg-3 `}>
                                <div
                                    className={`card mb-0 bg-hover-light  ${selectedCard === index ? 'text-black fw-bold' : ' text-black fw-bold'}`}
                                    style={{

                                        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease',

                                        zIndex: selectedCard === index ? 1 : 0,
                                        // border: selectedCard === index ? '3px solid #005380' : '',
                                        backgroundColor: selectedCard === index ? '#e6f6ff' : '#ffffff',
                                        boxShadow: selectedCard === index ? '0 0 0 3px #66c9ff' : 'none',

                                        transform: selectedCard === index ? 'scale(1.06)' : 'none',
                                        opacity: row.title === 'Adverse Events (MAUDE)' && (days === 30 || days === 90) ? 0.6 : 1,
                                        pointerEvents: row.title === 'Adverse Events (MAUDE)' && (days === 30 || days === 90) ? 'none' : 'auto',

                                    }}
                                    onClick={() => {
                                        if (!(row.title === 'Adverse Events (MAUDE)' && (days === 30 || days === 90))) {
                                            setSelectedCard(index);
                                            // The rest of the onClick logic remains the same
                                        }
                                        let selectedDocType;
                                        switch (row.title) {
                                            case 'Proposed Rules':
                                                selectedDocType = 'Proposed Rule';
                                                break;
                                            case 'Final Rules':
                                                selectedDocType = 'Rule';
                                                break;
                                            case 'Notice':
                                                selectedDocType = 'Notice';
                                                break;
                                            case 'Guidance':
                                                selectedDocType = "Guidance";
                                                break;
                                            case 'Adverse Events (MAUDE)':
                                                selectedDocType = "Adverse Events";
                                                break;
                                            case 'Warning Letters':
                                                selectedDocType = "Warning Letters";
                                                break;
                                            case 'Recalls':
                                                selectedDocType = "Recalls";
                                                break;
                                            default:
                                                selectedDocType = row.title
                                                break;
                                        }
                                        if (selectedDocType) {
                                            setDoctype(selectedDocType);

                                            Cookies.set('selectedDocType', selectedDocType);
                                        }
                                    }}
                                >
                                    <div className="card-body ps-3 pe-1 p-0   text-left" style={{

                                        height: '90px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'start',
                                        overflow: 'hidden', // Hide overflow content
                                        wordWrap: 'break-word' // Break long words to prevent overflow
                                    }}> {/* Change text-center to text-left */}
                                        {(() => {
                                            switch (row.title) {
                                                case 'Proposed Rules':
                                                    return (
                                                        <>
                                                            <p className=" mt-2 fs-1 fw-bolder text-gray-700">
                                                                {data ? proposedRuleCount?.toLocaleString() : ''}
                                                            </p>
                                                            <p className="mt-1 fs-6 fw-bolder text-gray-700">
                                                                Proposed Rules
                                                            </p>
                                                        </>
                                                    );
                                                case 'Final Rules':
                                                    return (
                                                        <>
                                                            <p className=" mt-2 fs-1 fw-bolder text-gray-700">
                                                                {data ? finalRuleCount?.toLocaleString() : ''}
                                                            </p>
                                                            <p className="mt-1 fs-6 fw-bolder text-gray-700">
                                                                Final Rules
                                                            </p>
                                                        </>
                                                    );
                                                case 'Notice':
                                                    return (
                                                        <>
                                                            <p className="mt-2 fs-1 fw-bolder text-gray-700">
                                                                {data ? noticeCount?.toLocaleString() : ''}
                                                            </p>
                                                            <p className="mt-1 fs-6 fw-bolder text-gray-700">
                                                                Notices
                                                            </p>
                                                        </>
                                                    );
                                                case 'Registrations':
                                                    return (
                                                        <>
                                                            <p className="mt-2 fs-1 fw-bolder text-gray-700">
                                                                {data ? registrationCount?.toLocaleString() : ''}
                                                            </p>
                                                            <p className="mt-1 fs-6 fw-bolder text-gray-700">
                                                                Registrations
                                                            </p>
                                                        </>
                                                    );

                                                case '510Ks':
                                                    return (
                                                        <>
                                                            <p className="mt-2 fs-1 fw-bolder text-gray-700">
                                                                {data ? five10kCount?.toLocaleString() : ''}
                                                            </p>
                                                            <p className="mt-1 fs-6 fw-bolder text-gray-700">
                                                                510Ks
                                                            </p>
                                                        </>
                                                    );
                                                case 'PMA':
                                                    return (
                                                        <>
                                                            <p className="mt-2 fs-1 fw-bolder text-gray-700">
                                                                {data ? pmaCount?.toLocaleString() : ''}
                                                            </p>
                                                            <p className="mt-1 fs-6 fw-bolder text-gray-700">
                                                                PMAs
                                                            </p>
                                                        </>
                                                    );

                                                case 'Guidance':
                                                    return (
                                                        <>
                                                            <p className="mt-2 fs-1 fw-bolder text-gray-700">
                                                                {data ? guidanceCount?.toLocaleString() : ''}
                                                            </p>
                                                            <p className="mt-1 fs-6 fw-bolder text-gray-700">
                                                                Guidances
                                                            </p>
                                                        </>
                                                    );

                                                case 'Recalls':
                                                    return (
                                                        <>
                                                            <p className="mt-2 fs-1 fw-bolder text-gray-700">
                                                                {data ? recallsCount?.toLocaleString() : ''}
                                                            </p>
                                                            <p className=" mt-1 fs-6 fw-bolder text-gray-700">
                                                                Recalls
                                                            </p>
                                                        </>
                                                    );
                                                case 'Adverse Events (MAUDE)':
                                                    return (
                                                        <>
                                                            <p className="mt-2 fs-1 fw-bolder text-gray-700">
                                                                {data ? eventsCount?.toLocaleString() : ''}
                                                            </p>
                                                            <p className="mt-1 fs-6 fw-bolder text-gray-700">
                                                                Adverse Events
                                                            </p>
                                                        </>
                                                    );
                                                case 'Warning Letters':
                                                    return (
                                                        <>
                                                            <p className="mt-2 fs-1 fw-bolder text-gray-700">
                                                                {data ? lettersCount?.toLocaleString() : ''}
                                                            </p>
                                                            <p className="mt-1 fs-6 fw-bolder text-gray-700">
                                                                Warning Letters
                                                            </p>
                                                        </>
                                                    );
                                                default:
                                                    return row.data.map((item, i) => (
                                                        <div key={i}>
                                                            <p className="mt-2 fs-1 fw-bolder text-gray-700">
                                                                {item}
                                                            </p>
                                                            <p className="mt-1 fs-6 fw-bolder text-gray-700">
                                                                {row.title}
                                                            </p>
                                                        </div>
                                                    ));
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


                </div>
                <div className='col-12 col-md-6 mt-1' style={{ borderRadius: '0.625rem', alignItems: 'center' }}>
                    {getWidget(doctype)}
                </div>

            </div>
            {Tablecontent}



        </div >
    )
}
export { CountAndTable }