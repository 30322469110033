import { Link, useParams } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import DocumentDetailsCard from './DocumentDetailsCard';
// import { Tab, Tabs } from 'react-bootstrap';
import FullWindow from '../PDFViewer/FullWindow';
// import TextViewer from './TextViewer';
import SummaryViewer from './SummaryViewer';
import FAQViewer from './FAQViewer';
import ImpactViewer from './ImpactViewer';
// import KeywordsViewer from './KeywordsViewer';
import ActionItemsViewer from './ActionItemsViewer';
import { useAuth } from '../../modules/auth';
import { useNavigate } from 'react-router-dom';
import DiscussionViewer from './DiscussionViewer';
import VideoViewer from './VideoViewer';
import Comments from '../Comments/Comments';
import HalfWindow from './HalfPDFViewer/HalfWindow';
import { QAInterface } from './QAInterface'; // Import the QAInterface component
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
// import { useSelector } from 'react-redux';

const DocumentDetails = () => {
    const { id } = useParams();
    const [fileText, setFileText] = useState('');
    const [document, setDocument] = useState([]);
    const location = useLocation();
    const activeTab = location.state ? location.state.activeTab : null;
    const prevPage = location.state ? location.state.prevPage : null;
    const searchInput = location.state ? location.state.searchInput : null;
    const currentPage = location.state ? location.state.currentPage : null;
    const [tab, setTab] = useState(activeTab || 'Document');
    const navigate = useNavigate();
    const { logout } = useAuth();
    const { currentUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    // const [trackedDocuments, setTrackedDocuments] = useState([]);
    const [summaryLikes, setSummaryLikes] = useState(0);
    const [summaryDislikes, setSummaryDislikes] = useState(0);
    const [userSummaryRating, setUserSummaryRating] = useState(0);

    const [actionLikes, setActionLikes] = useState(0);
    const [actionDislikes, setActionDislikes] = useState(0);
    const [userActionRating, setUserActionRating] = useState(0);

    const [faqLikes, setFAQLikes] = useState(0);
    const [faqDislikes, setFAQDislikes] = useState(0);
    const [userFAQRating, setUserFAQRating] = useState(0);

    const [impactLikes, setImpactLikes] = useState(0);
    const [impactDislikes, setImpactDislikes] = useState(0);
    const [userImpactRating, setUserImpactRating] = useState(0);

    const [ratingLoad, setratingLoad] = useState(true);
    const [countLoad, setCountLoad] = useState(true);

    // const addons = useSelector(state => state.account.addons);

    useEffect(() => {
        const fetchDocument = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`/reg/v1/document/${id}`);

                setDocument(response.data[0]);

                //fetchComments(response.data[0].iddocument); 
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    console.log('Error fetching document:', error);
                }
            }
            setIsLoading(false);
        };
        fetchDocument();
    }, [id, logout, navigate]);


    // const fetchTrackedDocuments = useCallback(async () => {

    //     try {
    //         const response = await axios.get(`reg/v1/document/user/rules/${currentUser?.id}`);
    //         setTrackedDocuments(response.data);
    //         console.log('Tracked documents:', response.data);
    //         //returning to filter for tracked documents
    //         return response.data;

    //     } catch (error) {
    //         console.log('Failed to fetch tracked documents', error);
    //         //returning to filter for tracked documents
    //         return [];
    //     }
    // }, [currentUser?.id]);

    // useEffect(() => {
    //     // Check if interests are loaded
    //         fetchTrackedDocuments();
    // }, [fetchTrackedDocuments]);


    // const handleTrackDocument = (id) => {
    //     // setLoading(true);
    //     axios.post(`/reg/v1/document/track/${currentUser?.id}/${id}`)
    //         .then((response) => {
    //             // setAlert({ message: response.data.message, type: 'success' });
    //             console.log('Document tracked:', response.data);
    //             fetchTrackedDocuments(); // Fetch tracked documents again after tracking a new document
    //             setTrackedDocuments(prevState => {

    //                 const newState = prevState.map(doc => {
    //                     if (doc.iddocument === id) {
    //                         // console.log('Toggling track for document:', doc);
    //                         return { ...doc, track: doc.track === 1 ? 0 : 1 };
    //                     } else {
    //                         return doc;
    //                     }
    //                 });
    //                 return newState;
    //             });
    //             console.log('Tracked documents after handlechange:', trackedDocuments);

    //             // setLoading(false);
    //         })
    //         .catch((error) => {
    //             if (error.response.status === 401) {
    //                 logout();
    //                 navigate('/auth/login');
    //             } else {
    //                 // setError(error);
    //             }
    //             // setLoading(false);
    //         });
    //     // fetchTrackedDocuments();
    // }


    useEffect(() => {
        fetch('/translation_hardcoded.txt')
            .then(response => response.text())
            .then(data => {
                setFileText(data);
            });
    }, []);

    const moment = require('moment-timezone');

    const formatDate1 = useCallback((timestamp) => {
        if (timestamp === null) {
            return null;
        }
        const date = moment.utc(timestamp); // Use UTC
        return date.format('YYYY-MM-DD'); // Format in UTC
    }, [moment]);

    const formatDate2 = useCallback((timestamp) => {
        if (isNaN(Date.parse(timestamp))) {
            return null;
        }
        const date = moment.utc(timestamp); // Use UTC
        return date.format('YYYY-MM-DD'); // Format in UTC
    }, [moment]);

    useEffect(() => {
        setratingLoad(true);
        const docId = id;
        const userId = currentUser?.id;
        axios.get(`/reg/v1/user/document/rating/${userId}/${docId}/action`)
            .then(response => {
                const data = response.data[0];
                // console.log("get user rating", data)
                if (data) {
                    setUserSummaryRating(data ? data.summary : 0);
                    setUserActionRating(data ? data.action : 0);
                    setUserFAQRating(data ? data.faq : 0);
                    setUserImpactRating(data ? data.impact : 0);
                }
                setratingLoad(false);
            })
            .catch(error => {
                console.error('Failed to fetch user document rating:', error);
                setratingLoad(false);
            });
    }, [currentUser?.id, id]);

    useEffect(() => {
        setCountLoad(true);
        const docId = id;
        axios.get(`/reg/v1/document/rating/${docId}`)
            .then(response => {
                const data = response.data[0];
                // console.log("get document count", data)
                setSummaryLikes(data.summary_total_pos);
                setSummaryDislikes(data.summary_total_neg);
                setActionLikes(data.action_total_pos);
                setActionDislikes(data.action_total_neg);
                setFAQLikes(data.faq_total_pos);
                setFAQDislikes(data.faq_total_neg);
                setImpactLikes(data.impact_total_pos);
                setImpactDislikes(data.impact_total_neg);
                setCountLoad(false);
            })
            .catch(error => {
                console.error('Failed to fetch document ratings:', error);
                setCountLoad(false);
            });
    }, [id]);


    return (
        <div>
            {!isLoading &&
                <div className="ms-1 mt-n2 pb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
                    <div style={{ marginRight: '20px', width: '250px' }}>
                        <Link
                            to={prevPage === "home" ? "/home" :
                                document.documentType === "Rule" ? "/final-rules" :
                                    document.documentType === "Proposed Rule" ? "/proposed-rules" :
                                        document.subtype === "Guidance" ? "/guidances" : "/final-rules"
                            }
                            state={{ docketId: document.docketId, agencyId: document.agencyId, searchInput: searchInput, currentPage: currentPage, agency: '', period_code: 0 }}
                            style={{
                                color: '#0d6efd',
                                textDecoration: 'none',
                                fontSize: '1.3em',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                            onMouseEnter={(e) => e.target.style.color = '#0d6efd'}
                            onMouseLeave={(e) => e.target.style.color = '#0d6efd'}
                        >
                            <KTIcon iconName='arrow-left' className='fs-2 me-2' style={{ marginRight: '10px' }} />
                            {
                                prevPage === "home" ? "Home Page" :
                                    document.documentType === "Rule" ? "Final Rules List" :
                                        document.documentType === "Proposed Rule" ? "Proposed Rules List" :
                                            document.subtype === "Guidance" ? "Guidance Documents List" : "Final Rules List"
                            }
                        </Link>
                    </div>
                </div>
            }

            <div className='card mt-3 mb-4' style={{}}>
                <div className='card-body d-flex align-items-center '>
                    <div className='position-relative flex-wrap'>
                        <div>
                            {/* {!isLoading && <h4 className='text-gray-800 fw-bold mb-3'>Document: {document.title}</h4>} */}
                            {(isLoading || ratingLoad || countLoad) && (
                                <div className="d-flex align-items-center">
                                    <div>Document Loading...</div>
                                    <div className="spinner-border spinner-border-sm ms-3 mb-1" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!isLoading && !ratingLoad && !countLoad &&
                                <>
                                    {/* <div className="d-flex justify-content-between"> */}
                                    <div className="d-flex align-items-center mb-1">
                                        <span className={`badge card-title fs-6 ${document?.documentType === 'Rule' ? 'badge-success' : document?.documentType === 'Proposed Rule' ? 'badge-info' : document?.subtype === "Guidance" ? 'badge-warning' : 'badge-danger'}`}>
                                            {document?.documentType === 'Other' ? document?.subtype : document?.documentType === 'Rule' ? 'Final Rule' : document?.documentType}
                                        </span>
                                        {document?.documentType === "Proposed Rule" && (
                                            <span className={`badge card-title fs-6 ms-2 badge-danger`}>
                                                {document?.subtype}
                                            </span>
                                        )}
                                    </div>
                                    {/* <div className='ms-1'>{trackedDocuments.some(trackedDocument => trackedDocument.id === document.id && trackedDocument.track === 1) ? (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip-2">Remove from My Items</Tooltip>}
                                        >
                                            {({ ref, ...triggerHandler }) => (
                                                <button
                                                    {...triggerHandler}
                                                    className="btn h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                                    onClick={() => handleTrackDocument(document.iddocument)}
                                                >
                                                    <i ref={ref} className="bi bi-bookmark-fill fs-1 ms-6" style={{ color: '#007bff' }}></i>
                                                </button>
                                            )}
                                        </OverlayTrigger>
                                    ) : (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip-2">Add to My Items</Tooltip>}
                                        >
                                            {({ ref, ...triggerHandler }) => (
                                                <button
                                                    {...triggerHandler}
                                                    className="btn h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                                    onClick={() => handleTrackDocument(document.iddocument)}
                                                >
                                                    <i ref={ref} className="bi bi-bookmark fs-1 ms-6" style={{ color: '#008000' }}></i>
                                                </button>
                                            )}
                                        </OverlayTrigger>
                                    )}</div> */}
                                    {/* </div> */}
                                    <div className="d-flex align-items-center  mb-1">
                                        <h4 className='text-gray-800 fw-bold mb-2'>Document: {document.title}</h4>

                                    </div>
                                    {document.subtype !== "Guidance" &&
                                        <div className="d-flex align-items-center  mb-1">
                                            <h5 className="card-title text-gray-600  " >Topics: <span className="">{document?.topics ? document.topics : "No Topics Available"}</span></h5>

                                        </div>
                                    }

                                    <div className="d-flex align-items-center  mb-1">
                                        <h5 className="card-title text-gray-600  " >Keywords: <span className="">{document?.keywords ? document.keywords : "No Topics Available"}</span></h5>

                                    </div>

                                    {document.subtype !== "Guidance" &&
                                        <div className="d-flex align-items-center  mb-1">
                                            <h5 className="card-title text-gray-600" >CFR Part: <span className="" >{document?.cfrPart ? document.cfrPart : "No CFR Part"}</span></h5>
                                            <span className="card-title px-2" >|</span>
                                            <h5 className="card-title text-gray-600">Subject: <span className="">{document?.subject ? document.subject : "No Subject Available"}</span></h5>
                                            <span className="card-title px-2">|</span>
                                            <h5 className="card-title text-gray-600">Page Count: <span className="">{document?.pageCount}</span></h5>

                                        </div>
                                    }
                                    <div className="d-flex align-items-center">
                                        <h5 className="card-title text-gray-600" >Posted: <span className=" " >{formatDate2(document.postedDate)}</span></h5>
                                        <span className="card-title px-2" >|</span>
                                        <h5 className="card-title text-gray-600" >Comment Start Date: <span className="">{document.commentStartDate !== null ? (formatDate1(document.commentStartDate)) : 'None'}</span></h5>
                                        <span className="card-title px-2" >|</span>
                                        <h5 className="card-title text-gray-600" >Comment End Date: <span className="">{document.commentEndDate !== null ? (formatDate1(document.commentEndDate)) : 'None'}</span></h5>
                                    </div>
                                    {
                                        document.commentEndDate === null || new Date(document.commentEndDate) > new Date() ? (
                                            <span className="badge badge-light" >
                                                <a href={`https://www.regulations.gov/docket/${document.docketId}`} target="_blank" rel="noopener noreferrer">Regulations.gov</a>
                                            </span>
                                        ) : (
                                            ''
                                        )
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <ul
                        className='nav nav-stretch nav-line-tabs
                        nav-line-tabs-2x
                        border-transparent
                        flex-nowrap'
                        role='tablist'
                    >
                        <li className='nav-item fs-4 '>
                            {/* <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Details' })}
                                onClick={() => setTab('Details')}
                                role='tab'
                            >
                                Details
                            </h5> */}
                        </li>
                        <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Document' })}
                                onClick={() => setTab('Document')}
                                role='tab'
                            >
                                {/* <KTIcon iconName='information' className='fs-5 me-1 text-primary' />  */}
                                Document
                            </h5>
                        </li>
                        {document.is_genai_exists === 1 ? <>
                            <li className={`nav-item fs-4 `} style={(ratingLoad || countLoad) ? { pointerEvents: 'none', opacity: 0.6 } : {}}>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Summary' })}
                                    onClick={() => !ratingLoad && !countLoad && setTab('Summary')}
                                    role='tab'
                                >
                                    Summary
                                </h5>
                            </li>
                            <li className='nav-item fs-4' style={(ratingLoad || countLoad) ? { pointerEvents: 'none', opacity: 0.6 } : {}}>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Impact' })}
                                    onClick={() => !ratingLoad && !countLoad && setTab('Impact')}
                                    role='tab'
                                >
                                    Impact
                                </h5>
                            </li>
                            <li className='nav-item fs-4 ' style={(ratingLoad || countLoad) ? { pointerEvents: 'none', opacity: 0.6 } : {}}>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'ActionItems' })}
                                    onClick={() => !ratingLoad && !countLoad && setTab('ActionItems')}
                                    role='tab'
                                >
                                    Action Items
                                </h5>
                            </li>
                            <li className='nav-item fs-4' style={(ratingLoad || countLoad) ? { pointerEvents: 'none', opacity: 0.6 } : {}}>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'FAQ' })}
                                    onClick={() => !ratingLoad && !countLoad && setTab('FAQ')}
                                    role='tab'
                                >
                                    {/* <KTIcon iconName='information' className='fs-3 me-1 text-primary' /> */}
                                    FAQ
                                </h5>
                            </li>
                        </> : ''}
                        {document.in_elastic === 1 ?
                            <li className='nav-item fs-4'>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'LeximQ&A' })}
                                    onClick={() => setTab('LeximQ&A')}
                                    role='tab'
                                >
                                    Lexim Q&A
                                </h5>
                            </li>
                            : ''}
                        <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'PublicSubmissions' })}
                                onClick={() => setTab('PublicSubmissions')}
                                role='tab'
                            >
                                Public Comments
                            </h5>
                        </li>
                        {/* {addons.translation &&
                            <li className='nav-item fs-4'>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Translation' })}
                                    onClick={() => setTab('Translation')}
                                    role='tab'
                                >
                                    Translation
                                </h5>
                            </li>
                        }
                        {addons.videoTraining &&
                            <li className='nav-item fs-4'>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Training' })}
                                    onClick={() => setTab('Training')}
                                    role='tab'
                                >
                                    Training
                                </h5>
                            </li>
                        } */}
                        <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Discussion' })}
                                onClick={() => setTab('Discussion')}
                                role='tab'
                            >
                                Discussion
                            </h5>
                        </li>
                    </ul>
                </div>

                <div className='card-body'>
                    <div className='tab-content'>
                        <div className={clsx('tab-pane', { active: tab === 'Details' })}>
                            {document && <DocumentDetailsCard document={document} loading={isLoading} />}
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'Document' })}>
                            {document.iddocument &&
                                <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white ">
                                    <FullWindow documentId={document.iddocument} />
                                </div>
                            }
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'PublicSubmissions' })}>
                            {document.iddocument &&
                                <Comments document={document} />

                            }
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'LeximQ&A' })}>
                            {document.iddocument &&
                                <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white ">
                                    {document.in_elastic !== 1 ? (
                                        <div className="d-flex flex-column align-items-center w-100 mt-20 text-wrap mx-auto vh-100">
                                            <div className={`alert alert-info alert-dismissible fade show mt-3`} role="alert">
                                                Q&A for this document is coming soon !
                                            </div>
                                            <img src="/media/illustrations/dozzy-1/2.png" alt="Background" style={{ maxWidth: '80%' }} />
                                        </div>
                                    ) : (
                                        <div className="row h-100">
                                            <div className="col-md-6">
                                                <HalfWindow documentId={document.iddocument} />
                                            </div>
                                            <div className="col-md-6 ">
                                                <QAInterface documentId={document.id} subtype={document.subtype} documentType={document.documentType} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'Summary' })}>
                            {document.id && !ratingLoad && !countLoad &&
                                <div className="">
                                    <SummaryViewer iddocument={document.id} likes={summaryLikes} dislikes={summaryDislikes} userRating={userSummaryRating} ratingLoad={ratingLoad || countLoad} />
                                </div>}
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'Impact' })}>
                            {document.id && !ratingLoad && !countLoad &&
                                <div className="">
                                    <ImpactViewer iddocument={document.id} likes={impactLikes} dislikes={impactDislikes} userRating={userImpactRating} ratingLoad={ratingLoad || countLoad} />
                                </div>}
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'FAQ' })}>
                            {document.id && !ratingLoad && !countLoad &&
                                <div className="">
                                    <FAQViewer iddocument={document.id} likes={faqLikes} dislikes={faqDislikes} userRating={userFAQRating} ratingLoad={ratingLoad || countLoad} />
                                </div>}
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'ActionItems' })}>
                            {document.id && !ratingLoad && !countLoad &&
                                <div className="">
                                    <ActionItemsViewer iddocument={document.id} likes={actionLikes} dislikes={actionDislikes} userRating={userActionRating} ratingLoad={ratingLoad || countLoad} />
                                </div>}
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'Translation' })}>
                            {document.id && <div><pre>{fileText}</pre> </div>}
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'Training' })}>
                            {document.id && <VideoViewer />}
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'Discussion' })}>
                            {document.id && <DiscussionViewer documentId={document.iddocument} />}
                        </div>
                    </div>
                </div>

            </div>

            {/* {document && <DocumentDetailsCard document={document} />} */}
            {/* <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="uncontrolled-tab-example" className="mb-3 mt-4">
                <Tab eventKey="details" title={<span style={{ fontSize: '14px' }}>Details</span>}>
                    {document && <DocumentDetailsCard document={document} loading={isLoading} />}
                </Tab>
                <Tab eventKey="document" title={<span style={{ fontSize: '14px' }}>Document</span>}>


                    {document.iddocument &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white ">
                            <FullWindow documentId={document.iddocument} />
                        </div>
                    } 
                </Tab> */}
            {/* <Tab eventKey="viewAsText" title={<span style={{ fontSize: '14px' }}>View as Text</span>}>
                   
                    {document.iddocument &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <TextViewer iddocument={document.iddocument} />
                        </div>}
                </Tab> */}
            {/* <Tab eventKey="publicSubmissions" title={<span style={{ fontSize: '14px' }}>Public Submissions</span>}>
                    {document.iddocument &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <Comments document={document} />
                        </div>}

                </Tab> */}
            {/* <Tab eventKey="docqa" title={<span style={{ fontSize: '14px' }}>Q&A</span>}>
                    {document.iddocument &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white ">


                            {document.in_elastic !== 1 ? (

                                <div className="d-flex flex-column align-items-center w-100 mt-20 text-wrap mx-auto vh-100"><div className={`alert alert-info alert-dismissible fade show mt-3`} role="alert">
                                    Q&A for this document is coming soon !

                                </div>
                                    <img src="/media/illustrations/dozzy-1/2.png" alt="Background" style={{ maxWidth: '80%' }} /></div>
                            ) : (
                                <div className="row h-100">
                                    <div className="col-md-6">
                                        <HalfWindow documentId={document.iddocument} />
                                    </div>
                                    <div className="col-md-6">
                                        <QAInterface documentid={document.id} />
                                    </div>
                                </div>
                            )}
                        </div>
                    }

                </Tab> */}
            {/* <Tab eventKey="summary" title={<span style={{ fontSize: '14px' }}>Summary</span>}>
                    {document.id &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <SummaryViewer iddocument={document.id} />
                        </div>}

                </Tab>
                <Tab eventKey="Impact" title={<span style={{ fontSize: '14px' }}>Impact</span>}>
                    {document.id &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <ImpactViewer iddocument={document.id} />
                        </div>}
                </Tab>
                <Tab eventKey="faq" title={<span style={{ fontSize: '14px' }}>FAQ</span>}>
                    {document.id &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <FAQViewer iddocument={document.id} />
                        </div>}
                </Tab> */}
            {/* <Tab eventKey="keywords" title="Keywords"> */}
            {/* Keywords content goes here */}
            {/* {document.id &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <KeywordsViewer iddocument={document.id} />
                        </div>}
                </Tab> */}
            {/* <Tab eventKey="actionItems" title={<span style={{ fontSize: '14px' }}>Action Items</span>}>
                    {document.id &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <ActionItemsViewer iddocument={document.id} />
                        </div>}
                </Tab>
                <Tab eventKey="translation" title={<span style={{ fontSize: '14px' }}>Translation</span>}>
                    {document.id &&
                        <div>
                            <pre>{fileText}</pre>
                        </div>}
                </Tab>
                <Tab eventKey="training" title={<span style={{ fontSize: '14px' }}>Training</span>}>
                    {document.id &&
                        <VideoViewer />}
                </Tab>
                <Tab eventKey="discussion" title={<span style={{ fontSize: '14px' }}>Discussion</span>}>
                    {document.id &&
                        <DiscussionViewer documentId={document.iddocument} />}
                </Tab>
            </Tabs> */}
        </div>
    );
};
export default DocumentDetails;