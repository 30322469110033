import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SearchBar from './SearchBar';
import { KTIcon } from '../../../_metronic/helpers';
import { LinksListPagination } from './LinksListPagination';

import { useParams } from 'react-router-dom';
const UsefulLinksTable = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'country', direction: 'desc' });
    const [searchInput, setSearchInput] = useState('');
    const [filtered, setFiltered] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(1);
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get('/reg/v1/user/resources');

                const filterData = id ? response.data.filter((item) => id === 'EU' ? item.country === 'European Union' : item.country === id) : response.data;
                setData(filterData);
                setFiltered(filterData);
                // console.log(response.data)
                setTotalPages(Math.ceil(response.data.length / itemsPerPage));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            setIsLoading(false);
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        if (searchInput.trim() === '') {
            setFiltered(data);
            setTotalPages(Math.ceil(data.length / itemsPerPage));
        } else {
            const searchTerm = searchInput.toLowerCase();
            const filtered = data.filter((item) => {
                return (
                    (item.country && item.country.toLowerCase().includes(searchTerm)) ||
                    (item.title && item.title.toLowerCase().includes(searchTerm))
                );
            });
            setFiltered(filtered);
            // console.log(filtered);
        }
    }, [data, searchInput]);

    const handleHeaderClick = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = React.useMemo(() => {
        let sortableData = [...filtered];
        if (sortConfig !== null) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [filtered, sortConfig]);

    const paginatedData = sortedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid px-1">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                            <div className="d-flex align-items-center ms-4">
                                <KTIcon iconName='share' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                Resources
                                {isLoading && (
                                    <div className="spinner-border text-primary ms-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                )}
                            </div>
                        </h1>
                    </div>
                </div>
                <div className="d-flex flex-wrap gap-3 flex-row-fluid px-19 align-items-center">
                    <h4 className="d-md-block text-wrap mt-1 text-muted">{!isLoading && <span> Over {filtered.length} results</span>}</h4>
                </div>
                <div className="d-flex flex-row-fluid align-items-center px-8 mt-2 mb-6">
                    <div className="col-8">
                        <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
                    </div>
                </div>
                {!isLoading && (
                    <div>
                        <div className='table-responsive px-5 mb-5'>
                            <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                                style={{
                                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                                    'tableLayout': 'fixed'
                                }}>
                                <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                                    <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                                        <th style={{ whiteSpace: 'nowrap', width: '16%', cursor: 'pointer' }} onClick={() => handleHeaderClick('country')}>
                                            Country
                                            {sortConfig.key === 'country' && (
                                                <span className={`sorting-icon ${sortConfig.direction}`}>
                                                    {' '}<i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                                </span>
                                            )}
                                        </th>
                                        <th style={{ whiteSpace: 'nowrap', width: '84%', cursor: 'pointer' }} onClick={() => handleHeaderClick('title')}>
                                            Title
                                            {sortConfig.key === 'title' && (
                                                <span className={`sorting-icon ${sortConfig.direction}`}>
                                                    {' '}<i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                                </span>
                                            )}
                                        </th>
                                    </tr>
                                </thead>
                                {isLoading ? (<tbody>
                                    <tr>
                                        <td
                                            colSpan="2"
                                            className='text-dark fw-semibold fs-5'
                                            style={{ textAlign: 'center' }}
                                        >
                                            Loading...
                                        </td>
                                    </tr>
                                </tbody>)
                                    : (
                                        <tbody>
                                            {sortedData.length === 0 ? (
                                                <tr>
                                                    <td
                                                        colSpan="2"
                                                        className='text-dark fw-semibold fs-5'
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        No records found
                                                    </td>
                                                </tr>
                                            ) : (
                                                paginatedData.map(item => (
                                                    <tr
                                                        key={item.id}
                                                        className='align-items-center bg-hover-light-primary'
                                                        style={{ cursor: 'default' }}
                                                    >
                                                        <td className='text-dark fw-semibold fs-6' style={{ overflowX: 'auto', width: '180px', }}>{item.country}</td>
                                                        <td className='text-dark fw-semibold fs-6' style={{ overflowX: 'auto', width: '400px' }}>
                                                            <a className='text-primary' href={item.link} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>{item.title}</a>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    )}
                            </table>
                        </div>
                        <LinksListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                )}

            </div>
        </div>
    );
};

export default UsefulLinksTable;